import React from 'react'
import Link from 'gatsby-link'
import moment from 'moment'
import { graphql } from "gatsby"
import Layout from "../components/layout"
import * as utils from '../utils'
import SocialLinks from "../components/sociallinks"

export const query = graphql`
  query IndexQuery {
    site {
      siteMetadata {
        title
      }
    }
    news: allMarkdownRemark(filter: {frontmatter: {type: {eq: "news"}}}) {
      edges {
        node {
          id
          headings {
            value
            depth
          }
          fields {
            slug
            title
          }
          frontmatter {
            nid
            vid
            type
            date
            year
            cover_filepath
            image_filepath
            official
            status
            timestamp
            spotifylink
            deezerlink
            ituneslink
            youtubelink
          }
          html
          excerpt
        }
      }
    }
    releases: allMarkdownRemark(filter: {frontmatter: {type:{glob: "*release"}}}) {
      edges {
        node {
          id
          headings {
            value
            depth
          }
          fields {
            slug
            title
          }
          frontmatter {
            nid
            vid
            type
            news
            date
            year
            cover_filepath
            image_filepath
            official
            status
            timestamp
            spotifylink
            deezerlink
            ituneslink
            youtubelink
          }
          html
          excerpt
        }
      }
    }
  }
`

export default ({data}) => {
  var releasesInNews = data.releases.edges
    .filter(t => t.node && t.node.fields && t.node.fields.slug)
    .filter(t => t.node && t.node.frontmatter && t.node.frontmatter.status === 1 && t.node.frontmatter.news === 1)

  var sortednews = [].concat(data.news.edges, releasesInNews)
    .filter(t => t.node && t.node.fields && t.node.fields.slug)
    .filter(t => t.node && t.node.frontmatter && t.node.frontmatter.status === 1)
    .map(t => ({...t, timestamp: Date.parse(t.node.frontmatter.date)}))
    .sort((a, b) => b.timestamp - a.timestamp)
    .slice(0, 20);

  var sortedreleases = data.releases.edges
    .filter(t => t.node && t.node.fields && t.node.fields.slug)
    .filter(t => t.node && t.node.frontmatter && t.node.frontmatter.type !== 'videorelease')
    .filter(t => t.node && t.node.frontmatter && t.node.frontmatter.status === 1 && t.node.frontmatter.official === 1)
    .map(t => ({...t, timestamp: Date.parse(t.node.frontmatter.date)}))
    .sort((a, b) => b.timestamp - a.timestamp)
    .slice(0, 10);

  return <Layout>
    <div className="rightside">
      <h2>Latest Releases</h2>
      <div className="covers">
        {
          sortedreleases.map(t => {
            let p = t.node.frontmatter.cover_filepath || t.node.frontmatter.image_filepath
            return p ? <span key={t.node.id} className="cover">
              <Link to={'/' + t.node.fields.slug}>
                <img src={utils.getImageURL(p, 300)} alt={t.node.fields.title} width="100" height="100" />
              </Link>
            </span> : null;
          })
        }
      </div>
    </div>
    <div className="content news">
      {
        sortednews.map((t, i) => {
          let p = t.node.frontmatter.image_filepath || t.node.frontmatter.cover_filepath
          if (t.node.frontmatter.type === 'videorelease') p = t.node.frontmatter.image_filepath
          return <span key={t.node.id}>
            {i > 0 ? <hr /> : null}
            {p ? <img src={utils.getImageURL(p, 1000)} alt={t.node.fields.title} className="fullwidth" /> : null}
            <div className="newscontent">
              <h3>
                <Link to={t.node.fields.slug}>
                  {t.node.fields.title}
                </Link>
                {' '}
                <em>{moment(t.node.frontmatter.date).format("YYYY-MM-DD")}</em>
              </h3>
              <div dangerouslySetInnerHTML={{ __html: t.node.html || 'x'}} />
              <SocialLinks frontmatter={t.node.frontmatter} />
            </div>
            <br />
          </span>;
        })
      }
    </div>
    <div className="mobilepadding">
      <p>
        <a href="/about/news-archive">Older news</a>
      </p>
    </div>
  </Layout>;
}

// export default <StaticQuery query={query} render={pageRender} />
